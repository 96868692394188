import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/users/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name, email, password }),
            });
            if (response.ok) {
                alert('Успешная регистрация!');
                navigate('/');
            } else {
                const data = await response.json();
                alert(data.error);
            }
        } catch (error) {
            console.error('Ошибка регистрации:', error);
        }
    };

    return (
        <div>
            <h1>Регистрация</h1>
            <form onSubmit={handleRegister}>
                <input type="text" placeholder="Имя" value={name} onChange={(e) => setName(e.target.value)} />
                <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type="password" placeholder="Пароль" value={password} onChange={(e) => setPassword(e.target.value)} />
                <button type="submit">Зарегистрироваться</button>
            </form>
        </div>
    );
};

export default Register;
