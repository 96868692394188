import React from 'react';

const Dashboard = () => {
    return (
        <div style={{ padding: '20px' }}>
            <h1>Панель управления</h1>
            <p>Добро пожаловать в панель управления!</p>
            <div>
                <h2>Разделы:</h2>
                <ul>
                    <li><a href="/products">Управление товарами</a></li>
                    <li><a href="/users">Управление пользователями</a></li>
                    <li><a href="/settings">Настройки</a></li>
                </ul>
            </div>
        </div>
    );
};

export default Dashboard;
